<template>
  <div>

    <create-user-popup
      v-if="showAddModal"
      @close="showAddModal = false"
      @submitted="refreshData(); showAddModal = false;"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <table-top
        show-add-button
        show-search
        @change:limit="limitChanged"
        @change:query="queryChanged"
        @click:add="showAddModal = true "
      />

      <rc-overlay :show="loading">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="users"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="loading ? $t('shared.loading') : $t('shared.no-data') "
          :sort-by.sync="query.sortBy"
          :sort-desc.sync="query.isSortDirDesc"
        >

          <!-- Column: surname -->
          <template #cell(surname)="data">

            <b-link
              v-if="canIEdit"
              class="text-nowrap"
              :to="{ name: 'user-edit', params: { id: data.item.id } }"
            >
              {{ data.item.surname }} {{ data.item.name }}
            </b-link>
            <span v-else>
              {{ data.item.surname }} {{ data.item.name }}
            </span>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="canIEdit"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editUser(data.item.id)"
              />
            </div>

          </template>

        </b-table>
      </rc-overlay>

      <table-bottom
        :query="query"
        :total="total"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BTable,
} from 'bootstrap-vue'
import TableTop from '@/layouts/components/table/TableTop.vue'
import CreateUserPopup from '@/views/users/popups/CreateUserPopup.vue'
import TableBottom from '@/layouts/components/table/TableBottom.vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import guard from '@/guard'

export default {
  components: {
    TableBottom,
    CreateUserPopup,
    TableTop,
    RcOverlay,

    BCard,
    BTable,
    BLink,
  },
  data() {
    return {
      showAddModal: false,
      loading: false,

      total: 0,
      query: {
        sortBy: null,
        isSortDirDesc: false,
        query: null,
        limit: 10,
        page: 1,
      },

      tableColumns: [
        {
          label: this.$t('conf.user.surname-name'),
          key: 'surname',
          sortable: true,
        },
        {
          label: this.$t('conf.user.position'),
          key: 'position',
          sortable: true,
        },
        {
          label: this.$t('conf.user.email'),
          key: 'email',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    users() {
      return this.$store.getters['user/getUsers']
    },
    canIEdit() {
      return guard.hasAtLeastOneRole(this.$store.state.auth.activeUser.roles, ['ROLE_USER_MANAGEMENT'])
    },
  },
  watch: {
    query: {
      handler() {
        this.refreshData()
      },
      deep: true,
    },
  },
  created() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      this.loading = true
      const query = {
        q: this.query.query,
        sortField: this.query.sortBy,
        sortDir: this.query.isSortDirDesc ? 'desc' : 'asc',
        limit: this.query.limit,
        page: this.query.page,
      }

      Promise.all([this.$store.dispatch('user/fetchUsers', query)])
        .then(res => {
          if (this.query.page > 1 && res[0].items.length === 0) {
            this.query.page = 1
          }
          this.total = res[0].total
          this.loading = false
        })
        .catch(err => console.log(err))
    },
    deleteUser(id) {
      return id
    },
    editUser(id) {
      this.$router.push(`/user/edit/${id}`)
    },
    limitChanged(newLimit) {
      this.query.limit = newLimit
    },
    queryChanged(newQuery) {
      this.query.query = newQuery
    },
  },
}
</script>

<style lang="scss">

.actions-column {
  width: 1rem;
}
</style>
