var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"addUserModal","title":_vm.$t('conf.user.popup.title'),"ok-title":_vm.$t('shared.button.submit'),"cancel-title":_vm.$t('shared.button.cancel'),"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('close')},"ok":_vm.submitForm}},[_c('validation-observer',{ref:"newUserValidation"},[_c('form',{attrs:{"id":"createUserForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.name_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.name_input'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.name_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.name),callback:function ($$v) {_vm.$set(_vm.newUserForm, "name", $$v)},expression:"newUserForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.surname_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"surname"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.surname_input'))+" ")]),_c('b-form-input',{attrs:{"id":"surname","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.surname_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.surname),callback:function ($$v) {_vm.$set(_vm.newUserForm, "surname", $$v)},expression:"newUserForm.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.position_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"position"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.position_input'))+" ")]),_c('b-form-input',{attrs:{"id":"position","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.position_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.position),callback:function ($$v) {_vm.$set(_vm.newUserForm, "position", $$v)},expression:"newUserForm.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.email_input'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.email_input'))+" ")]),_c('b-form-input',{attrs:{"id":"email","type":"email","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.email_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.email),callback:function ($$v) {_vm.$set(_vm.newUserForm, "email", $$v)},expression:"newUserForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.phone_input'),"rules":"digits:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.phone_input'))+" ")]),_c('b-form-input',{attrs:{"id":"phoneNumber","type":"number","minlength":"9","maxlength":"9","placeholder":_vm.$t('conf.user.popup.phone_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.newUserForm, "phoneNumber", $$v)},expression:"newUserForm.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"divider"})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.username_input'),"rules":"alpha-num|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.username_input'))+" ")]),_c('b-form-input',{attrs:{"id":"username","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.username_input'),"autocomplete":"off"},model:{value:(_vm.newUserForm.username),callback:function ($$v) {_vm.$set(_vm.newUserForm, "username", $$v)},expression:"newUserForm.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.password_input'),"rules":_vm.newUserForm.username ? 'required|min:6|max:40' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"basic-password"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.password_input'))+" ")]),_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.newPassword.length > 0},on:{"click":_vm.generatePassword}},[_c('feather-icon',{attrs:{"icon":_vm.newPassword.length > 0 ? 'LockIcon' : 'UnlockIcon'}})],1)],1),_c('b-form-input',{attrs:{"id":"basic-password","type":_vm.visiblePass ? 'text' : 'password',"minlength":"6","maxlength":"40","disabled":!_vm.newUserForm.username,"placeholder":_vm.$t('conf.user.popup.password_input'),"autocomplete":"new-password"},model:{value:(_vm.newUserForm.password),callback:function ($$v) {_vm.$set(_vm.newUserForm, "password", $$v)},expression:"newUserForm.password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.passwordVisibility}},[_c('feather-icon',{attrs:{"icon":_vm.visiblePass ? 'EyeOffIcon' : 'EyeIcon'}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.roles_input'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"userStatus"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.roles_input'))+" ")]),_c('v-select',{attrs:{"id":"userStatus","placeholder":_vm.$t('conf.user.popup.roles_input'),"options":_vm.roles,"multiple":"","clearable":false,"searchable":true},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(_vm.$t('roles.' + option.label))+" ")])}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t('roles.' + label)))])]}}],null,true),model:{value:(_vm.newUserForm.roles),callback:function ($$v) {_vm.$set(_vm.newUserForm, "roles", $$v)},expression:"newUserForm.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.newUserForm.requirePasswordChange),callback:function ($$v) {_vm.$set(_vm.newUserForm, "requirePasswordChange", $$v)},expression:"newUserForm.requirePasswordChange"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.change_pass_label_checkbox'))+" ")]),_c('b-form-checkbox',{attrs:{"disabled":_vm.teamLoginOnlyCheckboxDisabled},model:{value:(_vm.newUserForm.teamLoginOnly),callback:function ($$v) {_vm.$set(_vm.newUserForm, "teamLoginOnly", $$v)},expression:"newUserForm.teamLoginOnly"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.only_team_login_label_checkbox'))+" ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }