<template>
  <div>
    <b-modal
      id="addUserModal"
      :title="$t('conf.user.popup.title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >

      <validation-observer ref="newUserValidation">
        <form
          id="createUserForm"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.name_input')"
                rules="required|alpha-spaces|min:3"
              >
                <label for="name">
                  {{ $t('conf.user.popup.name_input') }}
                </label>
                <b-form-input
                  id="name"
                  v-model="newUserForm.name"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.user.popup.name_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.surname_input')"
                rules="required|alpha-spaces|min:3"
              >
                <label for="surname">
                  {{ $t('conf.user.popup.surname_input') }}
                </label>
                <b-form-input
                  id="surname"
                  v-model="newUserForm.surname"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.user.popup.surname_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.position_input')"
                rules="required|alpha-spaces|min:3"
              >
                <label for="position">
                  {{ $t('conf.user.popup.position_input') }}
                </label>
                <b-form-input
                  id="position"
                  v-model="newUserForm.position"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.user.popup.position_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.email_input')"
                rules="email"
              >
                <label for="email">
                  {{ $t('conf.user.popup.email_input') }}
                </label>
                <b-form-input
                  id="email"
                  v-model="newUserForm.email"
                  type="email"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.user.popup.email_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.phone_input')"
                rules="digits:9"
              >
                <label for="phoneNumber">
                  {{ $t('conf.user.popup.phone_input') }}
                </label>
                <b-form-input
                  id="phoneNumber"
                  v-model="newUserForm.phoneNumber"
                  type="number"
                  minlength="9"
                  maxlength="9"
                  :placeholder="$t('conf.user.popup.phone_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <hr class="divider">
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.username_input')"
                rules="alpha-num|min:3|max:50"
              >
                <label for="username">
                  {{ $t('conf.user.popup.username_input') }}
                </label>
                <b-form-input
                  id="username"
                  v-model="newUserForm.username"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.user.popup.username_input')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.password_input')"
                :rules="newUserForm.username ? 'required|min:6|max:40' : ''"
              >
                <label for="basic-password">
                  {{ $t('conf.user.popup.password_input') }}
                </label>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-primary"
                      :disabled="newPassword.length > 0"
                      @click="generatePassword"
                    >
                      <feather-icon :icon="newPassword.length > 0 ? 'LockIcon' : 'UnlockIcon'" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="basic-password"
                    v-model="newUserForm.password"
                    :type="visiblePass ? 'text' : 'password'"
                    minlength="6"
                    maxlength="40"
                    :disabled="!newUserForm.username"
                    :placeholder="$t('conf.user.popup.password_input')"
                    autocomplete="new-password"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="passwordVisibility"
                    >
                      <feather-icon :icon="visiblePass ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.user.popup.roles_input')"
                rules=""
              >
                <b-form-group>
                  <label for="userStatus">
                    {{ $t('conf.user.popup.roles_input') }}
                  </label>
                  <v-select
                    id="userStatus"
                    v-model="newUserForm.roles"
                    :placeholder="$t('conf.user.popup.roles_input')"
                    :options="roles"
                    multiple
                    :clearable="false"
                    :searchable="true"
                  >
                    <span
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ $t('roles.' + option.label) }}
                    </span>
                    <template #option="{ label }">
                      <span>{{ $t('roles.' + label) }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>

            </b-col>

            <b-col
              cols="12"
              class="pb-25"
            >
              <b-form-checkbox
                v-model="newUserForm.requirePasswordChange"
                class="mb-1"
              >
                {{ $t('conf.user.popup.change_pass_label_checkbox') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="newUserForm.teamLoginOnly"
                :disabled="teamLoginOnlyCheckboxDisabled"
              >
                {{ $t('conf.user.popup.only_team_login_label_checkbox') }}
              </b-form-checkbox>

            </b-col>

          </b-row>
        </form>

      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import {
  cloneNested, parseRequestError, randomPassword,
} from '@/helpers/helpers'
import {
  BButton,
  BCol, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  required, alphaNum, alphaSpaces, digits,
} from '@validations'

export default {
  components: {
    BModal,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      alphaSpaces,
      digits,
      visiblePass: false,
      newPassword: '',
      teamLoginOnlyCheckboxDisabled: true,
      newUserForm: cloneNested(this.$store.state.user.emptyUser),
    }
  },
  computed: {
    statuses() {
      return cloneNested(this.$store.getters['user/getUserStatuses'])
    },
    roles() {
      return cloneNested(this.$store.getters['auth/getUser'].roles)
    },
  },
  watch: {
    newUserForm: {
      handler(newUser) {
        if (typeof newUser.roles !== 'undefined') {
          this.processRolesChange(newUser.roles)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$bvModal.show('addUserModal')
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newUserValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('user/addUser', this.newUserForm)
            .then(() => {
              this.$bvModal.hide('addUserModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('conf.user.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
    processRolesChange(newUserRoles) {
      const canInitializeTeam = newUserRoles.includes('ROLE_PARAMEDIC')
      this.teamLoginOnlyCheckboxDisabled = !canInitializeTeam
      if (!canInitializeTeam) {
        this.newUserForm.teamLoginOnly = false
      }
    },
    passwordVisibility() {
      this.visiblePass = !this.visiblePass
    },
    generatePassword() {
      this.newUserForm.password = randomPassword()
    },
  },
}
</script>
